import axiosGoogle from "axios";

const API_GEOCODING_KEY = 'AIzaSyBxVox2FgBEoGMt6YwMPY2C_RAJreb68pY';
const API_GEOCODING_BASE_URL = 'https://maps.googleapis.com/maps/api/geocode/json?latlng=';
const API_GEOCODING_BY_ADDRESS_BASE_URL = 'https://maps.googleapis.com/maps/api/geocode/json?address=';
const API_PLACE_BY_PLACE_ID = 'https://maps.googleapis.com/maps/api/place/details/json?placeid=';

const instance = axiosGoogle.create({
  baseURL: '',
  headers: {}
});

delete instance.defaults.headers.common['Authorization'];

const GoogleMaps = {

    get(lng, lat, lang) {
      return new Promise((resolve, reject) => {
        instance
            .get(API_GEOCODING_BASE_URL + lng + ',' + lat + '&language=' + lang + '&key=' + API_GEOCODING_KEY)
            .then(response => {
              resolve(response.data.results.find(x => x.geometry.location_type == 'ROOFTOP').formatted_address);
            })
            .catch(error => {
              console.log("ERROR GOOGLE MAPS:", JSON.stringify(error));
              reject(false);
            });
      });
    },
    getByAddress(address, lang) {        
        return new Promise((resolve, reject) => {
            instance
                .get(API_GEOCODING_BY_ADDRESS_BASE_URL + encodeURIComponent(address) + '&language=' + lang + '&key=' + API_GEOCODING_KEY)
                .then(response => {
                    if(response.data.results != null) {
                        resolve(response.data.results[0].formatted_address);
                        //resolve(response.data.results.find(x => x.geometry.location_type == 'ROOFTOP').formatted_address);
                    }
                    resolve(1);
                })
                .catch(error => {
                    console.log("ERROR GOOGLE MAPS:", JSON.stringify(error));
                    reject(false);
                });
        });
    },
    getByPlaceId(placeId, lang) {        
      return new Promise((resolve, reject) => {
          instance
              .get(API_PLACE_BY_PLACE_ID + encodeURIComponent(placeId) + '&language=' + lang + '&key=' + API_GEOCODING_KEY)
              .then(response => {
                  if(response.data.result != null) {
                      resolve(response.data.result);
                  }
                  resolve(1);
              })
              .catch(error => {
                  console.log("ERROR GOOGLE MAPS:", JSON.stringify(error));
                  reject(false);
              });
      });
  },
};

export default GoogleMaps;
