<template>
  <div class="px-5 pt-10">

    <div class="bg-white text-center rounded mb-10">
      <router-link
        v-if="(this.phases) && this.phases.length > 1"
        :to="{
          name: 'processDetail',
          params: { id: this.processId }
        }"
      >
        <img :src = "processLogo" style="max-height: 120px; max-width: 120px;" alt="" />
      </router-link>
      <span v-else-if="(this.phases) && this.phases.length == 1">
        <router-link
            v-if="(this.phases[0]) && (this.phases[0].process2Modules) && this.phases[0].process2Modules.length > 1"
            :to="{
              name: 'processPhase',
              params: {
                process_id: this.processId,
                phase_id: this.phases[0].id
              }
            }"
            class="text-primary"
        >
          <img :src = "processLogo" style="max-height: 120px; max-width: 120px;" alt="" />
        </router-link>
        <router-link
            v-else
            :to="{
              name: 'processModuleIdeaList',
              params: {
                id: this.phases[0].process2Modules[0].id,
                process_id: this.processId,
                phase_id: this.phases[0].id
              }
            }"
            class="text-primary"
        >
          <img :src = "processLogo" style="max-height: 120px; max-width: 120px;" alt="" />
        </router-link>
      </span>
      <span v-else>
        <img :src = "processLogo" style="max-height: 120px; max-width: 120px;" alt="" />
      </span>

    </div>

    <div class="d-flex align-items-center mt-5 mb-5" v-for="(phase, index) in phases" :key="phase.id">
      <!-- <span class="bullet bullet-bar align-self-stretch" ></span> -->
      <div class="d-flex flex-column flex-grow-1 ml-3 text-center" v-if="phases.length > 1">
        <!-- se la fase è in attivazione: lancia alert -->
        <div v-if="phase.phaseStatus.id == 1"
          class="p-2 mb-2 font-weight-bolder"
          style="margin-left: -10px;"
        >
          <a
              class="label label-circle bg-secondary text-dark-75 p-8 h3 font-weight-bolder"
              href="#"
              @click="alertNotActive"
          >
            {{ index + 1 }}
          </a>
        </div>

        <!-- se la fase è in corso -->
        <div
          v-else-if="phase.phaseStatus.id == 2"
          class="p-2 mb-2 font-weight-bolder"
          style="margin-left: -10px;">
          <!-- se la fase ha un unico modulo, vai a quel modulo -->
          <router-link
              v-if="phase.process2Modules.length == 1"

            :to="{ name: 'processModuleIdeaList', params: { id: phase.process2Modules[0].id, process_id: processId, phase_id: phase.id } }"
          >
            <span class="label label-circle bg-primary text-white p-8 h3 font-weight-bolder">
              {{ index + 1 }}
            </span>
          </router-link>
          <!-- se la fase contiene più moduli, vai alla pagina di fase -->
          <router-link
              v-else
            :to="{ name: 'processPhase', params: { phase_id: phase.id, process_id: processId } }"
          >
            <span class="label label-circle bg-primary text-white p-8 h3 font-weight-bolder">
              {{ index + 1 }}
            </span>
          </router-link>
        </div>

        <!-- se la fase è conclusa -->
        <div
          v-else
          class="p-2 mb-2 font-weight-bolder"
          style="margin-left: -10px;">
          <!-- se la fase ha un unico modulo, vai a quel modulo -->
          <router-link
              v-if="phase.process2Modules.length == 1"
              :to="{ name: 'processModuleIdeaList', params: { id: phase.process2Modules[0].id, process_id: processId, phase_id: phase.id } }"
          >
            <span class="label label-circle bg-secondary text-dark-75 p-8 h3 font-weight-bolder">
              {{ index + 1 }}
            </span>
          </router-link>
          <!-- se la fase contiene più moduli, vai alla pagina di fase -->
          <router-link
              v-else
            :to="{ name: 'processPhase', params: { phase_id: phase.id, process_id: processId } }"
          >
            <span class="label label-circle bg-secondary text-dark-75 p-8 h3 font-weight-bolder">
              {{ index + 1 }}
            </span>
          </router-link>
        </div>
        <div class="p font-weight-bolder text-dark-75">
          <div v-b-toggle.collapse-test class="border-0">
             <span class="navi-text">{{ phase.title }} </span>
          </div>
        </div>
<!--        <b-collapse  id="collapse-test">-->
<!--          <ul class="navi text-left">-->
<!--            <div v-for="item in phase.process2Modules" :key="item.id">-->
<!--              <ModulePreviewCard-->
<!--                  :module="item"-->
<!--                  :phase="phase">-->
<!--              </ModulePreviewCard>-->
<!--              &lt;!&ndash; &ndash;&gt;-->
<!--            </div>-->
<!--          </ul>-->
<!--        </b-collapse>-->
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import Swal from "sweetalert2";
// import ModulePreviewCard from "@/view/pages/process/partials/ModulePreviewCard";

export default {
  name: "Phaseline",
  components: {
    // ModulePreviewCard
  },
  props: ["phases", "processId", "processLogo"],
  data() {
    return {
      last: 0
    };
  },
  computed: {
    getNrCol() {
      // return this.phase.process2Modules.length > 1 ? 6 : 12;
      let col;
      switch (this.phase.process2Modules.length) {
        case 1: {
          col = 12;
          break;
        }
        default: {
          col = 6;
          break;
        }
      }

      console.log('', );

      return col;
    },
    // getModuleIcon() {
    //   return this.module.module.icon
    //     ? this.module.module.icon.uri
    //     : "/assets/media/bipart/ico-municipality.png";
    // },
  },
  mounted() {
    setTimeout(() => {
      this.last = this.phases ? this.phases.length - 1 : 0;
    }, 500 );
  },
  methods: {
    isWaiting(dateO) {
      let d1 = moment(new Date()).format("YYYYMMDD");
      let d2 = moment(dateO).format("YYYYMMDD");

      return d1 < d2 ? true : false;
    },
    isOpened(dateO, dateC) {
      let d1 = moment(dateO).format("YYYYMMDD");
      let d2 = moment(new Date()).format("YYYYMMDD");
      let d3 = moment(dateC).format("YYYYMMDD");
      return d1 < d2 < d3 ? true : false;
    },
    isClosed(dateC) {
      let d1 = moment(new Date()).format("YYYYMMDD");
      let d2 = moment(dateC).format("YYYYMMDD");

      return d1 > d2 ? true : false;
    },
    alertNotActive(e){
      e.preventDefault();
      Swal.fire({
        title: this.$t("PARTIALSPROCESSI.ALERT2TITLE"),
        text: this.$t("PARTIALSPROCESSI.ALERT2"),
        icon: "warning",
        confirmButtonClass: "btn btn-secondary"
      });
    }
  }
};
</script>

<style scoped></style>
